<template>
  <CommonPages id="home" classs="home">
    HI
    <section class="finger">
      <button @click="$getVisitorDataExtended({ignoreCache: true})">
        Get visitor data
      </button>
      <p v-if="visitorDataExtended?.isLoading">
        Loading...
      </p>
      <p v-else>
        VisitorId: {{ visitorDataExtended?.data?.visitorId }}
      </p>
      <p v-if="visitorDataExtended?.error">
        {{ visitorDataExtended.error.message }}
      </p>
      <pre v-else>{{ visitorDataExtended.data }}</pre>
    </section>

    <hr>
    <div class="client-info">
      <h1>用户设备信息</h1>
      <div>
        <p>浏览器指纹：{{ fingerprint }}</p>
        <p>浏览器名称：{{ browser }}</p>
        <p>浏览器版本：{{ browserVersion }}</p>
        <p>操作系统：{{ os }}</p>
        <p>设备类型：{{ deviceTypes }}</p>
        <p>User Agent：{{ userAgent }}</p>
        <p>Screen Print：{{ screenPrint }}</p>
        <p>Color Depth：{{ colorDepth }}</p>
        <p>当前分辨率：{{ currentResolution[0] }} x {{ currentResolution[1] }}</p>
        <p>可用分辨率：{{ availableResolution[0] }} x {{ availableResolution[1] }}</p>
        <p>deviceXDPI：{{ deviceXDPI }}</p>
        <p>deviceYDPI：{{ deviceYDPI }}</p>
        <p>插件列表：<span v-for="(plugin, index) in plugins" :key="index">{{ plugin.name }} </span></p>
        <p>字体列表：<span v-for="(font, i) in fonts" :key="i">{{ font }} </span></p>
        <p>支持LocalStorage：{{ hasLocalStorage }}</p>
        <p>支持SessionStorage：{{ hasSessionStorage }}</p>
        <p>时区偏移：{{ timezone }}</p>
        <p>语言：{{ language }}</p>
        <p>支持Cookies：{{ hasCookies }}</p>
        <p>Canvas Print：{{ canvasPrint }}</p>
      </div>
      <div v-if="isMobile" class="mobile-view">
        <p>当前为移动设备视图</p>
      </div>
      <div v-else class="pc-view">
        <p>当前为PC设备视图</p>
      </div>
    </div>
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import CommonPages from '@/pages/components/page_common.vue'
import '@/css/home.scss';
import { fpjsGetVisitorDataExtendedMixin } from '@fingerprintjs/fingerprintjs-pro-vue-v2'

export default {
  name: 'Home',
  components: {
    CommonPages,
  },
  mixins: [common, fpjsGetVisitorDataExtendedMixin],
  data () {
    return {
      fingerprint: '',
      browser: '',
      browserVersion: '',
      os: '',
      deviceTypes: '',
      isMobile: false,
      userAgent: '',
      screenPrint: '',
      colorDepth: '',
      currentResolution: [],
      availableResolution: [],
      deviceXDPI: '',
      deviceYDPI: '',
      plugins: [],
      fonts: [],
      hasLocalStorage: false,
      hasSessionStorage: false,
      timezone: '',
      language: '',
      hasCookies: false,
      canvasPrint: ''
    };
  },
  async mounted () {
    await this.$getVisitorDataExtended();

    // 仅在客户端环境下使用 clientjs
    if (typeof window !== 'undefined') {
      const { ClientJS } = await import('clientjs');
      const client = new ClientJS();
      console.log('aaaa');
      console.log(client);
      this.fingerprint = client.getFingerprint()
      this.browser = client.getBrowser()
      this.browserVersion = client.getBrowserVersion()
      this.os = client.getOS()
      this.isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(client.getUserAgent())
      this.deviceTypes = this.isMobile ? 'Mobile' : 'PC'
      this.userAgent = client.getUserAgent()
      this.screenPrint = client.getScreenPrint()
      this.colorDepth = client.getColorDepth()
      this.currentResolution = client.getCurrentResolution()
      this.availableResolution = client.getAvailableResolution()
      this.deviceXDPI = client.getDeviceXDPI()
      this.deviceYDPI = client.getDeviceYDPI()
      this.plugins = client.getPlugins()
      this.fonts = client.getFonts()
      this.hasLocalStorage = client.isLocalStorage()
      this.hasSessionStorage = client.isSessionStorage()
      this.timezone = client.getTimeZone()
      this.language = client.getLanguage()
      this.hasCookies = client.isCookie()
      this.canvasPrint = client.getCanvasPrint()
    }
  },
};
</script>